import $ from "jquery";

const $previewBtn = $("#preview_btn");
const $from = $("#renewal_form");
$previewBtn.on("click", () => {
    const query = $from.serialize();
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", `/admin/renewal/preview?${query}`);
    form.setAttribute("target", "_blank");
    document.body.appendChild(form);
    form.submit();
});
