import $ from 'jquery';
import "bootstrap/dist/js/bootstrap";
import "metismenu/dist/metisMenu"
import "sb-admin-2/dist/js/sb-admin-2";


import Vue from 'vue';

const init = () => {
    const page_inited = global["page-inited"] || false;
    if (!page_inited) {

        document.querySelectorAll("*[data-page]").forEach((ele, index) => {
            const alias = ele.getAttribute("data-page");

            import(
                /* webpackMode: "eager" */
                /* webpackInclude: /\.js$/ */
                /* webpackExclude: /(webpack)/ */
                `./page/${alias}.js`
                );

            global["page-inited"] = true;
        });
    }
}

/**
 * render vue component
 */
const initComponent = () => {
    const componentInited = global["componentInited"] || false;

    if (!componentInited) {
        const elementList = document.querySelectorAll("*[data-component]");

        elementList.forEach((ele) => {
            const alias = ele.getAttribute("data-component"),
                props = JSON.parse(ele.innerText.length > 0 ? ele.innerText : "{}") || {};

            console.log(ele.innerText);

            import(
                /* webpackMode: "eager" */
                /* webpackInclude: /\.vue/ */
                `./component/${alias}.vue`
                )
                .then((component) => {

                    new Vue({
                        el: ele,
                        render: h => h(component.default, {props})
                    });
                });

        });
    }

    global["componentInited"] = true;
};

init();
initComponent();
